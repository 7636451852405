.us {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    h1 {
        text-transform: uppercase;
    }
    h2 {
        text-transform: uppercase;
        // font-size: 4rem;
        font-weight: 200;
    }
    .image1-parallax {
        height: 400px;
        width: 100%;
        background-image: url("../../images/building-2.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
    .image-wrapper {
        width: 100%;
        height: 400px;
        // height: 100%;
        position: relative;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .section_indicator {
        border-top: 2px solid $secondary-color;
        border-bottom: 0;
        padding-bottom: 0 !important;
        span {
            line-height: 1;
            padding-top: 1rem;
        }
    }
    .section_dark {
        background-color: #000;
        color: #fff;
    }
    .section_indicator_end {
        margin-top: 1rem;
        border-bottom: 2px solid $secondary-color;
        span {
            font-size: 0.68rem;
            font-weight: 300;
            line-height: 1;
            width: 100%;
            padding-bottom: 1rem;
        }
    }
    .our-numbers {
        display: grid;
        grid-template-columns: 12vw 12vw 28vw 21vw 12vw;
        gap: 2rem;
        min-height: 250px;
        @media (max-width: 1300px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .ourCompanyContainer h2 {
                text-align: center;
            }
        }
    }
    .ourCompanyContainer {
        margin: 0.35rem;
        text-align: left;
        h2 {
            // text-align: left;
            font-size: 5vw;
            // font-weight: 200;
        }
        p {
            text-align: center;
            text-decoration: underline $secondary-color 1px;
            text-transform: uppercase;
            font-weight: 200;
            width: 70%;
        }
    }
    .brochureContainer {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-top: 3rem;
        p {
            font-weight: 300;
            // font-size: 1rem;
            color: #211915;
        }
    }
    .brochureBtn {
        color: white;
        background-color: $primary-color;
        padding: 1rem;
        text-transform: uppercase;
        font-weight: 200;
        border-width: 0;
        // border-radius: 3px;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        p {
            font-size: 0.7rem;
            margin-bottom: 0;
        }
        i {
            margin-left: 1rem;
        }
        &:hover {
            background: $tertiary-color;
        }
    }
    .slider-left-button,
    .slider-right-button {
        background-color: #000;
        color: white;
        border: none;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 25px;
        }
    }
    .slider-left-button {
        transform: translateY(-50%);
        left: -50px;
        i {
            transform: rotate(180deg);
        }
    }
    .slider-right-button {
        right: -50px;
    }
    @media (max-width: 992px) {
        margin-top: 2rem;
        .slider-left-button,
        .slider-right-button {
            width: 30px;
            height: 30px;
            i {
                font-size: 15px;
            }
        }
        .slider-left-button {
            transform: translateY(-50%);
            left: 0px;
        }
        .slider-right-button {
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .culture-slider {
        // overflow: hidden;
        padding: 1rem 0;
        position: relative;
        .owl-carousel {
            overflow: hidden;
        }
    }
    .culture-item {
        border: solid 2px #000;
        border-right: none;
        position: relative;
        // width: 250px;
        min-height: 300px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        h5 {
            text-transform: uppercase;
            font-weight: 200;
            font-size: 1.7rem;
            text-align: center;
            gap: 1.5rem;
            i {
                font-size: 70px;
                color: $primary-color;
            }
        }
        span {
            z-index: 10;
        }
        &>div {
            height: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
            padding: 1rem;
            z-index: 20;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 0%;
                min-height: 0%;
                opacity: 0;
                z-index: -1;
                background-color: $tertiary-color;
                transition: 0.75s;
            }
            & p {
                opacity: 0;
                color: #fff;
                transition: opacity 0.4s;
            }
        }
        &:hover>div {
            &::after {
                min-height: 100vh;
                min-width: 100vw;
                // display: block;
                opacity: 1;
            }
            & p {
                opacity: 1;
                transition: opacity 1s;
            }
        }
    }
    .numbers-info {
        // background-color: red;
        min-height: 250px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.4rem;
        padding: 2.5rem;
        color: #fff;
        background-color: #191919;
        text-transform: uppercase;
        border-top: solid thin #fff;
        padding-bottom: 5rem;
        h3 {
            font-size: 2rem;
            font-weight: 200;
            line-height: 60px;
            span {
                font-size: 5rem;
                font-weight: 200;
            }
        }
        p {
            color: $tertiary-color;
            font-weight: 300;
            font-size: 0.8rem;
        }
        div {
            // text-align: center;
        }
    }
    .trajectory {
        margin-top: 2.5rem;
        h3 {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 15px;
            margin-top: 3rem;
        }
        &>div:nth-child(1) {
            h2 {
                color: $primary-color;
            }
        }
        .table-years {
            &>div {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-top: solid thin black;
                padding: 15px 25px;
                .selected {
                    color: $primary-color;
                }
                span {
                    padding: 0;
                    color: #191919;
                    font-size: 20px;
                    font-weight: 300;
                    position: relative;
                    cursor: pointer;
                    i {
                        color: $tertiary-color;
                        position: absolute;
                        left: -25px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 16px;
                    }
                }
            }
        }
        .trajectory-slider {
            position: relative;
            .slider-left-button,
            .slider-right-button {
                top: 55%;
            }
            .slider-right-button {
                right: 1rem;
            }
            .slider-left-button {
                left: 1rem;
            }
            .trajectory-item {
                h2 {
                    margin-bottom: 1rem;
                }
                .value {
                    font-size: 40px;
                    font-weight: 200;
                    margin-bottom: -10px;
                }
                .measure {
                    text-transform: uppercase;
                    padding-top: 1px;
                    display: block;
                    font-size: 13px;
                    font-weight: 300;
                }
                .location {
                    font-size: 30px;
                    font-weight: 200;
                }
                img {
                    margin: 0.75rem 0;
                    height: 55vh;
                    filter: grayscale(100%);
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
    .contact-section {
        background-color: #cccfca;
        .contact-content {
            padding: 2.5rem;
        }
        h2 {
            font-weight: 300;
            font-size: 26.5px;
            max-width: 700px;
            text-align: justify;
            text-transform: initial;
            margin-bottom: 3rem;
        }
        .form-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 10px;
            gap: 0 2rem;
             :not(input[name="name"]):not(input[name="surname"]) {
                grid-column: 1/3;
            }
            @media (max-width: 992px) {
                grid-template-columns: 1fr;
                input {
                    grid-column: 1/3;
                }
            }
            input,
            textarea {
                border: none;
                border-bottom: solid thin #191919;
                padding: 10px 0;
                font-size: 1.3rem;
                background-color: transparent;
                color: #000;
                font-weight: 200;
                margin-top: 0.75rem;
                &::placeholder {
                    color: #000;
                    text-transform: uppercase;
                    font-weight: 200;
                }
                &:focus {
                    outline: none;
                    border-bottom: solid thin $tertiary-color;
                }
            }
        }
        .send-contact {
            all: unset;
            color: #000;
            font-weight: 200;
            border-bottom: 1px solid #000;
            text-decoration: none;
            font-size: 2rem;
            cursor: pointer;
            text-transform: uppercase;
            margin-top: 1rem;
        }
        .alert-contact {
            margin-top: 2rem;
            margin-bottom: 2rem;
            background-color: $tertiary-color;
            color: #fff;
            display: block;
            padding: 10px 15px;
            max-width: max-content;
            span {
                font-size: 18px;
                font-weight: 200;
            }
        }
        .brochureBtn {
            background-color: #000;
            i {
                transform: rotate(180deg);
                font-size: 20px;
            }
        }
    }
    .caretUp {
        // background-color: red;
        div {
            transform: rotate(-90deg) !important;
        }
        i {
            font-size: 50px;
        }
    }
    // @media(max-width:1400px) {
    //     .us .culture-item>div  {
    //         font-size: 1.3rem;
    //     }
    // }
    @media (max-width: 992px) {
        margin-top: 1rem;
        h1 {
            // font-size: 45px;
        }
        h2 {
            font-size: 25px;
        }
        .trajectory {
            h3 {
                margin-top: 1rem;
            }
        }
        .brochureBtn {
            padding: 0.5rem;
        }
        .our-numbers {
            gap: 0.5rem;
            .ourCompanyContainer {
                h2 {
                    font-size: 12vw;
                }
                p {
                    font-size: 4vw;
                    margin-bottom: 0;
                }
            }
        }
        .brochureContainer {
            flex-wrap: wrap;
            margin-top: 1rem;
        }
        .culture-item {
            h5 {
                font-size: 25px;
            }
            &>div {
                & p {
                    font-size: 15px;
                }
            }
        }
        .numbers-info {
            justify-content: center;
            text-align: center;
            padding-bottom: 2rem;
            h3 {
                font-size: 1rem;
                span {
                    font-size: 2.5rem;
                }
            }
            p {
                font-size: 0.6rem;
            }
        }
        .contact-section {
            .contact-content {
                padding: 1rem;
                .form-inputs {
                    padding: 10px;
                    gap: 0 2rem;
                    input,
                    textarea {
                        padding: 10px;
                        font-size: .75rem;
                        margin-top: 0.3rem;
                        &::placeholder {
                            color: #000;
                            text-transform: uppercase;
                            font-weight: 200;
                        }
                    }
                }
            }
            h2 {
                font-size: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}