footer {
    .chatBubble {
        width: 4rem;
        margin-left: auto;
        margin-right: 0;
    }
    .bubbleContainer {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: c;
    }
    padding: 2rem 0;
    .access {
        color: #9d9d9d !important;
    }
    a.navigator {
        font-size: 3.7rem;
        font-weight: 200;
        color: $secondary-color;
        display: block;
        line-height: 2.8rem;
        padding-bottom: 2rem;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        .hover {
            display: block;
            overflow: hidden;
            i {
                font-size: 2.5rem;
                transition: 0.4s ease all;
                display: block;
                margin-top: 0;
                margin-left: -100px;
                transform: rotate(-90deg);
                color: #e68811;
            }
        }
        &:hover {
            color: $primary-color;
            .hover {
                i {
                    margin-left: 0px;
                }
            }
        }
        &.active {
            color: $primary-color;
            .hover {
                i {
                    margin-left: 0;
                }
            }
        }
    }
    .contact-data {
        line-height: 43px;
    }
    .content-newsletter {
        border: 1px solid $secondary-color;
        display: flex;
        padding: 0;
        margin-bottom: 1rem;
        overflow: hidden;
        input {
            border: none;
            font-size: 0.7rem;
            padding: 1.2rem 1.5rem;
            width: 90%;
            font-weight: 300;
            &::placeholder {
                color: #211915;
                text-transform: uppercase;
            }
        }
        .content-arrow {
            width: 10.01%;
            background-color: #211915;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease all;
            img {
                width: 1rem;
            }
            &:hover {
                background-color: $primary-color;
            }
        }
    }
    p,
    a.mailto {
        font-size: 2.27rem;
        color: $secondary-color;
        line-height: 1.2;
        font-weight: 300;
        text-decoration: none;
        margin-bottom: 0;
        transition: 0.7s ease all;
    }
    p {
        margin-top: 1rem;
        line-height: 1.4;
    }
    a:hover {
        color: $primary-color;
    }
    .wordSpacing {
        margin-top: 1rem;
        word-spacing: 10px;
        font-size: 2.27rem;
        line-height: 1.2;
        font-weight: 300;
    }
    .social-media {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        .single {
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            transition: 0.4s ease all;
            align-items: center;
            justify-content: center;
            // background-color: $secondary-color;
            cursor: pointer;
            margin-right: 2rem;
            text-decoration: none;
            .small-single {
                width: 0.9rem;
            }
            img {
                width: 1.5rem;
            }
            i {
                font-size: 1.4rem;
                color: $secondary-color;
            }
            &:hover {
                i {
                    color: $primary-color;
                }
            }
        }
    }
    .copy {
        font-weight: 500;
        color: #9d9d9d;
        font-size: 0.8rem;
        font-family: "Circular";
        a {
            font-weight: 600;
            color: #9d9d9d;
            text-decoration: none;
        }
        a.mediahaus-link {
            color: #00a1ff;
        }
        a.mediacore-link {
            font-weight: 600;
        }
        span.reservated {
            font-size: .7rem;
        }
    }
    &.dark {
        background-color: $secondary-color;
        a.navigator {
            color: #fff;
        }
        .access {
            color: #fff !important;
        }
        p,
        a.mailto {
            color: #fff;
        }
        .social-media {
            .single {
                i {
                    color: #fff;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
        .content-newsletter {
            background-color: transparent;
            border-color: #fff;
            input {
                background-color: transparent;
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
            }
            .content-arrow {
                border-color: #fff;
                background-color: transparent;
                color: #fff;
                border-left: 1px solid #fff;
                &:hover {
                    background-color: $primary-color;
                }
            }
        }
        .contact-data span {
            color: #fff;
        }
        .separator {
            background-color: #fff !important;
        }
        h6 {
            color: #fff !important;
        }
    }
    .logo-ficha {
        height: 2rem
    }
    .separator {
        height: 2rem;
        margin: 0 1rem; // margin: 0rem 1rem;
        background-color: #000;
        width: 2px;
        letter-spacing: 0
    }
}

@media (max-width: 992px) {
    footer {
        .chatBubble {
            width: 3rem;
            margin-left: auto;
            margin-right: 0;
        }
        .logo-footer {
            margin-bottom: 1.2rem;
            height: 1.8rem;
        }
        &.ficha {
            padding: 1rem 0;
            // background-color: $secondary-color;
            text-align: center;
            .logo-ficha {
                display: block;
                margin: auto;
                width: 4rem;
            }
        }
        a.navigator {
            font-size: 1.4rem;
            padding-bottom: 0;
            line-height: normal;
            display: flex;
            justify-content: center;
            &::after {
                width: 1rem;
                height: 1rem;
                display: none;
                opacity: 0;
            }
            &.active {
                &::after {
                    display: none;
                }
            }
        }
        .content-newsletter {
            display: none !important;
        }
        .social-media {
            width: 100%;
            .single {
                margin-right: 0.5rem;
                padding: 1rem;
                i {
                    color: black;
                    font-size: 0.9rem;
                }
                .small-single {
                    width: 0.6rem;
                }
            }
        }
        .copy {
            font-size: 0.5rem;
            line-height: normal;
            display: block;
            text-align: center;
            span.reservated {
                font-size: .4rem;
            }
        }
    }
}