@font-face {
    font-family: 'Proto Grotesk';
    src: url('../../fonts/ProtoGrotesk-Bold.eot');
    src: url('../../fonts/ProtoGrotesk-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/ProtoGrotesk-Bold.woff') format('woff'), url('../../fonts/ProtoGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proto Grotesk';
    src: url('../../fonts/ProtoGrotesk-Light.eot');
    src: url('../../fonts/ProtoGrotesk-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/ProtoGrotesk-Light.woff') format('woff'), url('../../fonts/ProtoGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('../../fonts/Gordita-Regular.eot');
    src: url('../../fonts/Gordita-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Gordita-Regular.woff') format('woff'), url('../../fonts/Gordita-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proto Grotesk';
    src: url('../../fonts/ProtoGrotesk-ExtraLight.eot');
    src: url('../../fonts/ProtoGrotesk-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../../fonts/ProtoGrotesk-ExtraLight.woff') format('woff'), url('../../fonts/ProtoGrotesk-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proto Grotesk';
    src: url('../../fonts/ProtoGrotesk-Regular.eot');
    src: url('../../fonts/ProtoGrotesk-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/ProtoGrotesk-Regular.woff') format('woff'), url('../../fonts/ProtoGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circular';
    src: url('../../fonts/CircularStd-Book_1.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circular';
    src: url('../../fonts/CircularStd-Bold.otf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}