.Contacto,
.contact-section {
    .obligatory {
        font-size: 1vw;
        font-weight: 300;
        text-transform: uppercase;
    }
    .form {
        background-color: #cccfca;
        .social-media {
            .single {
                margin-right: 1rem;
                i {
                    transition: 0.3s ease all;
                    color: black;
                }
                &:hover {
                    i {
                        color: $primary-color;
                    }
                }
            }
        }
        h5 {
            font-size: 2.27rem;
            font-weight: 300;
        }
    }
}

@media (max-width: 992px) {
    .Contacto,
    .contact-section {
        background-color: $primary-color;
        .obligatory {
            font-size: 3vw;
        }
        .form {
            h5 {
                font-size: 1.59rem;
                margin: 2.2rem 0 1.5rem 0;
            }
            input::placeholder,
            textarea::placeholder,
            input,
            textarea {
                color: $secondary-color;
            }
            button:hover {
                color: $secondary-color;
            }
            .social-media {
                width: 100%;
                .single {
                    margin-right: 0.3rem;
                    width: 1.4rem;
                    height: 1.4rem;
                    margin-top: 0;
                    background-color: transparent;
                    i {
                        color: $secondary-color;
                        font-size: 0.7rem;
                    }
                }
            }
            #text-form {
                font-size: 0.7rem;
                color: #fff !important;
                background-color: $primary-color;
                padding: 0.2rem;
            }
        }
    }
}