.Emprendimiento {
    .oportunity {
        padding-top: 2.6rem;
        padding-bottom: 5rem;
        h5 {
            // font-size: 2.09rem;
            color: $secondary-color;
            // font-weight: 200;
            margin-bottom: 1rem;
        }
        p {
            // font-weight: 300;
            // line-height: 2.15rem;
            letter-spacing: -.5px;
            // font-size: 1.75rem;
            margin-bottom: 3rem;
        }
        .noClickeable {
            img {
                z-index: 0;
                cursor: auto;
                height: 60vh;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .icon {
            background-image: url('../../images/expand.svg');
            width: 1.4rem;
            height: 1.4rem;
            background-position: center;
            background-size: cover;
            display: inline-block;
        }
        .open_gallery {
            text-decoration: none;
            margin: 1.5rem auto;
            text-align: center;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            .notVisible {
                opacity: 0;
                height: 30px;
                width: 100%;
                position: absolute;
                z-index: 99;
            }
            span {
                color: $secondary-color;
                text-transform: uppercase;
                font-weight: 200;
                span {
                    border-bottom: 1px solid $secondary-color;
                }
            }
            img {
                width: 1.5rem;
                height: 1.5rem;
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .oportunity {
            padding-top: 2rem;
            padding-bottom: 2rem;
            h5 {
                // font-size: 1.1rem;
                // font-weight: 300;
                margin-bottom: .3rem;
            }
            p {
                // font-size: 1rem;
                // line-height: 1;
                // font-weight: 200;
            }
            .noClickeable {
                img {
                    height: 30vh;
                    margin-bottom: 1rem;
                }
            }
            .open_gallery {
                margin: .75rem 0;
                margin-top: .25rem;
                img {
                    width: 1rem;
                    height: 1rem;
                }
                span {
                    font-weight: 300;
                    font-size: .9rem;
                }
            }
        }
    }
}