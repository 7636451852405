.Contacto {
    .main {
        .mail {
            color: black;
            text-decoration: none;
            transition: 0.3s ease all;
            &:hover {
                color: $primary-color;
            }
        }
        background-color: #cccfca;
        padding-top: 9rem;
        h2 {
            font-size: 7vw;
            font-weight: 200;
            text-transform: uppercase;
        }
        h5 {
            text-transform: uppercase;
            font-size: 2.1rem;
            font-weight: 300;
        }
        span {
            font-size: 1.2rem;
            text-transform: uppercase;
            text-decoration: underline;
            font-weight: 200;
        }
        #middle {
            border-left: 1px solid $secondary-color;
            border-right: 1px solid $secondary-color;
        }
        #middle,
        #end,
        #end-flex,
        #start {
            border-bottom: 2px solid $secondary-color;
            border-top: 2px solid $secondary-color;
            margin-top: 3rem;
            font-weight: 200;
            font-size: 1.3rem;
            padding: 1.2rem 0;
        }
        &.dark-theme {
            #middle,
            #end,
            #end-flex,
            #start {
                border-bottom: 2px solid $primary-color;
                border-top: 2px solid $primary-color;
                margin-top: 3rem;
                font-weight: 200;
                font-size: 1.3rem;
                padding: 1.2rem 0;
            }
            #middle {
                border-left: 1px solid $primary-color;
                border-right: 1px solid $primary-color;
            }
        }
    }
}

@media (max-width: 992px) {
    .Contacto {
        .main {
            padding-top: 4rem;
            h2 {
                font-size: 2.36rem;
            }
            h5 {
                font-size: 0.8rem;
            }
            span {
                font-size: 0.8rem;
                margin-top: 0.5rem;
            }
            #middle {
                border-right: none;
                border-left: none;
            }
            #middle,
            #end,
            #end-flex,
            #start {
                padding: 0.6rem 0;
                margin-top: 0;
                border-bottom: none;
                border-top: 1px solid $secondary-color;
                font-size: 0.8rem;
            }
            #end-flex {
                border-bottom: 1px solid #000;
            }
        }
    }
}