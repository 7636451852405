    .Novedades {
        .main {
            margin-bottom: 5rem;
            padding-top: 8rem;
            padding-bottom: 7rem;
            border-bottom: solid 2px #000;
            h1 {
                // font-size: 7vw;
                // font-weight: 200;
                text-transform: uppercase;
            }
            h2 {
                font-weight: 300;
            }
            &.dark-theme {
                .outstanding-novelty {
                    &::-webkit-scrollbar-track {
                        border-left: 6px solid #191919 !important;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-left: 6px solid#191919 !important;
                    }
                }
                .all-novelties {
                    .novelties-grid {
                        &::-webkit-scrollbar-track {
                            border-left: 6px solid #191919 !important;
                        }
                        &::-webkit-scrollbar-thumb {
                            border-left: 6px solid#191919 !important;
                        }
                    }
                }
            }
            .filters {
                .filter-search {
                    background-color: #000;
                    color: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                    div {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                    }
                    .icon-label {
                        font-size: 25px;
                    }
                }
                .label {
                    background-color: #fff;
                    color: #000;
                    border: solid thin #191919;
                    padding: 10px 15px;
                    margin: 0;
                    display: inline;
                    text-transform: uppercase;
                    font-weight: 300;
                    font-size: 18px;
                    transition: 0.2s;
                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                    }
                    i {
                        font-size: 15px;
                        margin-left: 10px;
                    }
                }
                .search {
                    font-size: 35px;
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    padding: 0 10px;
                    transition: .3s ease all;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            .subtitle-notes {
                @media (max-width: 992px) {
                    & > div:nth-child(2) {
                        display: none;
                    }
                    h2 {
                        font-size: 20px;
                        margin-bottom: 0;
                        font-weight: 200;
                    }
                }
            }
            .mosaic-grid {
                margin-top: 1rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                min-height: 40vh;
                // grid-template-rows: 80vh;
                // overflow: hidden;
                gap: 1.5rem;
                @media (max-width: 992px) {
                    grid-template-columns: 1fr;
                }
            }
            .outstanding-novelty,
            .all-novelties {
                &>h2 {
                    margin-bottom: 1.5rem;
                }
            }
            .outstanding-novelty {
                max-height: 70vh;
                height: 100%;
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                &::-webkit-scrollbar {
                    width: 12px;
                    background: #ccc;
                }
                &::-webkit-scrollbar-track {
                    background: #ccc;
                    border-left: 6px solid #fff;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $primary-color;
                    border-radius: 3px;
                    border-left: 6px solid #fff;
                }
                &>div {
                    flex: 1;
                    // height: 100%;
                }
                .novelty-content-item {
                    max-height: 70vh;
                    margin-bottom: 5vh;
                    img {
                        width: 100%;
                        height: 70vh;
                        object-fit: cover;
                    }
                }
            }
            .all-novelties {
                overflow: hidden;
                height: 100%;
                max-height: 70vh;
                .novelties-grid {
                    overflow: auto;
                    min-height: calc(100% - 0px);
                    height: 70vh;
                    grid-gap: 5px !important;
                    // height: 100%;
                    // min-height: 70vh;
                    &::-webkit-scrollbar {
                        width: 12px;
                        background: #ccc;
                    }
                    &::-webkit-scrollbar-track {
                        background: #ccc;
                        border-left: 6px solid white;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $primary-color;
                        border-radius: 3px;
                        border-left: 6px solid white;
                    }
                    .novelty-content-item {
                        // width: 48%;
                        height: unset;
                        overflow: hidden;
                        img {
                            min-height: calc(365px - 0.75rem);
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .hover {
                            h2 {
                                font-size: 1.5rem;
                            }
                            p {
                                font-size: 0.75rem;
                            }
                            a {
                                width: 35px;
                                height: 35px;
                                font-size: 30px;
                                bottom: 0.5rem;
                                right: 0.5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
            .novelties-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-auto-rows: 50%;
                // display: flex;
                // flex-wrap: wrap;
                // align-items: stretch;
                // gap: 1rem 1rem;
                @media (max-width: 992px) {
                    grid-template-columns: 1fr;
                }
            }
            @media (max-width: 992px) {
                padding-top: 0rem;
                .all-novelties {
                    max-height: none;
                    .novelties-grid {
                        height: min-content;
                        display: flex;
                        flex-direction: column;
                        .novelty-content-item {
                            // height: 400px;
                        }
                    }
                }
                .outstanding-novelty {
                    overflow: hidden;
                    height: auto;
                    max-height: none !important;
                    padding: .5rem;
                    background-color: #000;
                }
                .outstanding-novelty,
                .all-novelties {
                    &>h2 {
                        margin-bottom: 1.5rem;
                        margin-top: 2rem;
                        font-size: 1rem;
                    }
                    .novelty-content-item {
                        // width: 48%;
                        img {
                            min-height: calc(365px - 0.75rem);
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .hover {
                            h2 {
                                font-size: 1rem !important;
                            }
                            p {
                                font-size: 0.75rem;
                            }
                            a {
                                width: 35px;
                                height: 35px;
                                font-size: 30px;
                                bottom: 0.5rem;
                                right: 0.5rem;
                                margin: 0.5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
                .all-novelties {
                    overflow: auto;
                }
            }
            @media (max-width: 992px) {
                margin-top: 0rem;
                padding-top: 4rem;
                padding-bottom: 2rem;
                .all-novelties {
                    // overflow: auto;
                }
                .filters {
                    .filter-search {
                        padding: 0.5rem;
                        div {
                            gap: 0.5rem;
                            .labels {
                                display: block;
                                .label {
                                    margin-right: 3px;
                                }
                            }
                        }
                    }
                    .icon-label {
                        font-size: 15px;
                    }
                    .label {
                        padding: 7px 10px;
                        font-size: 12px;
                        i {
                            font-size: 12px;
                            margin-left: 10px;
                        }
                    }
                    .search {
                        font-size: 25px;
                        padding: 0 5px;
                    }
                }
            }
        }
        .main {
            &.dark-theme {
                margin-bottom: 0;
                .filters {
                    .filter-search {
                        background-color: #fff !important;
                        color: $secondary-color !important;
                    }
                    .label {
                        background-color: $secondary-color !important;
                        color: #fff !important;
                    }
                    .search {
                        color: $secondary-color !important;
                    }
                }
            }
        }
    }