.Emprendimiento {
    .main {
        // min-height: calc(100vh);
        position: relative;
        .background {
            width: 100%;
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
            filter: grayscale(100%);
        }
        h3 {
            // font-size: 6rem;
            // font-weight: 200;
            // line-height: 0.85;
            text-transform: uppercase;
        }
        .save-favorite {
            font-weight: 300;
            border: none;
            background-color: transparent;
            i {
                transition: 0.5s ease all;
                color: black;
                &.selected {
                    color: $primary-color;
                }
            }
            &:hover {
                i {
                    color: $primary-color;
                    // -webkit-text-stroke: 1px $primary-color;
                }
            }
        }
        #text-main {
            min-width: 30%;
            p {
                font-size: 1.6rem;
                line-height: 1;
                font-weight: 200;
                margin-bottom: 0;
            }
        }
    }
    .video-title {
        text-transform: uppercase;
        font-weight: 300;
        // font-size: 28px;
    }
}

@media (max-width: 992px) {
    .Emprendimiento {
        .main {
            min-height: unset;
            .background {
                height: 30vh !important;
                margin-top: 3rem;
                background-attachment: unset;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
            h3 {
                // font-size: 2.6rem;
                // line-height: 0.9;
            }
            .save-favorite {
                font-size: 4vw;
            }
            #text-main {
                p {
                    font-size: .75rem;
                }
            }
        }
    }
}