.Inicio {
  .services {
    background-color: $secondary-color;
    padding-bottom: 3rem;
    .section_indicator {
      padding: 1rem 0;
      border-color: #fff;
      span {
        color: #fff;
      }
    }

    h2, h3 {
      text-transform: uppercase;
      .underline {
        border-bottom: 2px solid #fff;
      }
    }
    h5 {
      font-size: 0.9rem;
      color: #fff;
      font-weight: 200;
      text-transform: uppercase;
    }
    i.preassets {
      color: $primary-color;
      font-size: 3rem;
      display: block;
      margin-top: 5rem;
      margin-bottom: 2rem;
      margin-left: 4rem;
    }
    p {
      line-height: 1.1;
      margin-top: 1rem;
    }

    .serviceText {
      display: flex;
      flex-direction: column;
      justify-content: start;
      max-width: 50%;
    }
  }
}

@media (max-width: 992px) {
  .Inicio {
    .services {
      padding-bottom: .5rem;
      max-height: unset;
      h2 {
        
        br {
          display: none;
        }
      }
      i.preassets {
        margin-left: 0;
        margin-top: 2rem; 
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
      h5 {
        color: $primary-color;
        font-size: 0.59rem;
      }
      p {
        width: 70%;
      }
  
      .serviceText {
        max-width: 100%;
      }
    }
  }
}
