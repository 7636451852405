.Servicios {
  .services {
    padding-bottom: 3rem;
    border-bottom: 1px solid $secondary-color;

    .content-services {
      &:hover {
        .content-img i {
          transform: scale(1.25);
        }
      }
    }
    .content-img {
      width: 9rem;
      border: 2px solid $secondary-color;
      height: 9rem;
      i {
          transition: transform .75s cubic-bezier(0.95, 0.03, 0.515, 0.955);
        color: $primary-color;
        font-size: 3.5rem;
      }
    }
    .content {
      width: 85%;
      span {
        font-size: 0.9rem;
        color: $primary-color;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        display: block;
      }
      h2 {
        // font-size: 2.2rem;
        // font-weight: 300;
        display: block;
        margin-bottom: 1rem;
      }
      p {
        // font-size: 1.45rem;
        // font-weight: 300;
        // line-height: 1.1;
        // max-width: 900px;
      }
    }
  }
}

@media (max-width: 992px) {
  .Servicios {
    .services {
      padding-bottom: 1rem;
      .content-img {
        width: 4rem;
        height: 4rem;
        border: 1px solid $secondary-color;
        i {
          font-size: 2rem;
        }
      }
      .content {
        width: 100%;
        span {
          font-size: 0.59rem;
          display: inline-block;
        }
        h2 {
          // font-size: 1.2rem;
          // line-height: 1;
          display: inline-block;
        }
        p {
          // font-size: 0.72rem;
          // display: inline-block;
          // font-weight: 200;
        }
      }
    }
  }
}
