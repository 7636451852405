.Inicio {
  .about {
    padding-bottom: 5rem;
    h2 {
      .underline {
        border-bottom: 2px solid $secondary-color;
      }
    }
  }
}

@media (max-width: 992px) {
  .Inicio {
    .about {
      padding-bottom: 2.5rem;
      h2 {
        .underline {
          border-bottom: 1px solid $secondary-color;
        }
        br {
          display: none;
        }
      }
     
      a {
        margin-top: 1rem;
        display: inline-block;
      }
    }
  }
}
