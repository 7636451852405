.contact {
    width: 100%;
    input,
    textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        font-size: 1.3rem;
        font-weight: 200;
        margin-bottom: 0.3rem;
        padding: 0.8rem 0;
        border-bottom: 1px solid #000;
        &:focus {
            outline: none;
        }
    }
    // button,
    a {
        color: #000;
        border: none;
        border-bottom: 1px solid #000;
        font-weight: 200;
        cursor: pointer;
        background-color: transparent;
        font-size: 2.4rem;
        transition: 0.4s ease all;
        margin-top: 1.5rem;
        &:hover {
            color: $primary-color !important;
        }
    }
}

@media (max-width: 992px) {
    .contact {
        input,
        textarea {
            font-size: 0.8rem;
            padding: 0.4rem;
        }
        button {
            font-size: 1.36rem;
        }
    }
}