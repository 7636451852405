.Novedad {
    font-weight: 300;
    .novelty {
        padding-top: 8rem;
        padding-bottom: 5rem;
        border-bottom: solid 2px #000;
        .rdw-editor-toolbar {
            display: none;
        }
        &.dark-theme {
            .demo-editor {
                span {
                    color: #fff !important;
                }
            }
            .novelty-date {
                div {
                    background-color: #fff !important;
                }
            }
        }
        .demo-editor {
            .rdw-image-center {
                display: block;
            }
            .rdw-image-alignment-options-popup {
                display: none !important;
            }
            span {
                font-size: 1.2rem !important;
                line-height: 1 !important;
                // font-weight: 300 !important;
                color: #212529 !important;
                background-color: transparent !important;
                font-family: 'Proto Grotesk', "system-ui" !important;
            }
            h1 {
                span {
                    font-size: 3.5rem !important;
                    color: #212529;
                }
            }
            h2 {
                span {
                    font-size: 2rem !important;
                    color: #212529;
                }
            }
            h3 {
                span {
                    font-size: 1.75rem !important;
                    color: #212529;
                    font-weight: 300;
                }
            }
            a {
                span {
                    color: $primary-color !important;
                }
            }
            iframe {
                width: 100%;
                height: 70vh;
            }
        }
        h1 {
            font-weight: 300;
            font-size: 3.5rem;
            max-width: 60%;
        }
        h2 {
            font-weight: 300;
            font-size: 1.75rem;
            line-height: 38px;
        }
        p {
            font-size: 20px;
            font-weight: 300;
            line-height: 34px;
        }
        .social-novelty {
            a {
                width: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-color;
                color: #fff;
                height: 3rem;
                color: #fff;
                border: none;
                margin-left: .5rem;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    color: $primary-color;
                    border: 1px solid $primary-color;
                }
                &.small {
                    width: 2rem;
                    font-size: .9rem;
                    height: 2rem;
                }
            }
            .hidden-social {
                opacity: 0;
                transition: .4s ease all;
                &.open {
                    opacity: 1;
                }
            }
        }
        a:not([class]) {
            color: $primary-color;
        }
        .fix-height {
            max-height: 650px;
            min-height: 650px;
            width: 100%;
            background-attachment: fixed;
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .novelty-date {
            display: flex;
            align-items: center;
            span {
                color: $primary-color;
                font-weight: 300;
            }
            div {
                width: 20px;
                height: 1px;
                margin: 0 5px;
                background-color: #000;
            }
        }
        .tag {
            background-color: #fff;
            color: #000;
            border: solid thin #191919;
            padding: 10px 15px;
            margin: 0;
            margin-right: 10px;
            display: inline;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 18px;
            transition: 0.2s;
        }
        @media (max-width: 992px) {
            margin-top: 0rem;
            padding-bottom: 0;
            padding-top: 5rem;
            // .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
            //     display: none !important;
            // }
            .black-link {
                display: flex;
                width: fit-content;
                margin: auto;
            }
            h1 {
                font-size: 2rem;
                line-height: 1.1;
                max-width: 100%;
            }
            h2 {
                font-size: 1.25rem;
                margin: 2rem 0;
            }
            p {
                font-size: 16px;
            }
            .tag {
                font-size: .6rem;
            }
            .novelty-date {
                span {
                    font-size: 0.75rem;
                }
            }
            .fix-height {
                background-attachment: unset;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .social-novelty {
                margin-bottom: 1rem;
                a {
                    width: 2rem;
                    height: 2rem;
                    font-size: .8rem;
                    &.small {
                        height: 1.2rem;
                        width: 1.2rem;
                        font-size: .7rem;
                    }
                }
            }
        }
    }
}