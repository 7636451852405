.emprendimientos {
    .section-grid {
        background-color: $secondary-color;
        .section-name {
            h5 {
                font-size: .68rem;
                font-weight: 300;
                text-transform: uppercase;
                color: #fff;
                padding: 1rem 0;
                margin-bottom: 0;
                span {
                    width: .5rem;
                    height: .5rem;
                    background-color: #fff;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }
        form {
            .content-select {
                position: relative;
                z-index: 1;
                select {
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid #fff;
                    padding: .5rem 1rem;
                    color: #fff;
                    font-size: 1.5rem;
                    font-weight: 200;
                    -webkit-appearance: none;
                    padding-right: 3rem;
                    &:focus {
                        outline: none;
                    }
                    option {
                        background-color: $secondary-color;
                        color: #fff;
                        font-size: 1rem;
                        font-weight: 200;
                    }
                }
                i {
                    position: absolute;
                    z-index: -1;
                    right: 1.5rem;
                    top: calc(50% - 1rem);
                    width: 1.2rem;
                    height: 1.2rem;
                    display: block;
                    border-left: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    transform: rotate( -45deg);
                    transition: all 0.25s ease;
                }
            }
        }
    }
    .border-bottom {
        border-bottom: 1px solid #fff;
    }
}

.grid-development {
    margin-top: 3.8rem;
    .card-grid {
        position: relative;
        overflow: hidden;
        color: #191919;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            opacity: 0;
            background-color: #d17604;
            transition: .4s ease all;
            mix-blend-mode: screen;
            z-index: 9;
        }
        .img {
            width: 100%;
            object-fit: cover;
            background-position: center;
            background-size: cover;
            min-height: 20vw;
            filter: grayscale(100%);
        }
        .hover-text {
            position: absolute;
            left: .5rem;
            z-index: 99;
            top: .5rem;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: .4s ease all;
            h5 {
                margin-bottom: 0;
                line-height: 1;
                font-weight: 400;
                font-size: 2rem;
            }
            p {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 0;
                transition: margin-top .6s;
                margin-top: 200%;
            }
            a {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
                background-color: #191919;
                height: 3rem;
                width: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 3rem;
                text-decoration: none;
                margin-bottom: 0;
                padding-bottom: .5rem;
                font-size: 2rem;
                font-weight: 400;
                color: #fff;
            }
        }
        &:hover {
            &::before {
                opacity: 1;
            }
            .hover-text {
                opacity: 1;
                p {
                    margin-top: 0;
                }
            }
        }
    }
    button {
        background-color: transparent;
        color: #fff;
        text-transform: uppercase;
        font-weight: 200;
        display: inline-block;
        width: max-content;
        margin-top: 3.8rem;
        border: none;
        text-decoration: underline;
    }
}

@media (max-width:992px) {
    .emprendimientos {
        .section-grid {
            .section-name {
                font-size: .35rem;
            }
            form {
                .content-select {
                    select {
                        font-size: .65rem;
                        padding: .3rem .6rem;
                        margin-bottom: .8rem;
                        position: relative;
                        option {
                            font-size: .65rem;
                        }
                    }
                    i {
                        width: .5rem;
                        height: .5rem;
                        position: absolute;
                        top: 20%;
                        right: 15px;
                    }
                }
            }
        }
    }
    .grid-development {
        margin-top: 1rem;
        .card-grid {
            .img {
                height: 80vw;
            }
            .hover-text {
                opacity: 1;
                top: .8rem;
                position: relative;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.582);
                // margin-bottom: 2rem;
                left: 0;
                color: #fff !important;
                h5 {
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    margin-bottom: .5rem;
                }
                p {
                    margin-top: 0;
                    line-height: 1.2;
                    font-size: .85rem;
                    max-width: 75%;
                }
                a {
                    background-color: $primary-color;
                    color: #fff;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
        button {
            margin-top: 1rem;
        }
    }
}