.Inicio {
    .oportunity {
        span {
            font-size: 1rem;
            font-weight: 300;
            color: $secondary-color;
        }
        .border-bottom-secondary {
            width: 100%;
            height: 2px;
            background-color: $secondary-color;
            // margin-bottom: 0.5rem;
        }
        .dark {
            .owl-theme .owl-nav {
                margin-top: 0;
            }
        }
    }
}