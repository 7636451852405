.Emprendimiento {
    .files {
        padding-bottom: 3rem;
        a {
            text-decoration: none;
            color: $secondary-color;
            font-weight: 300;
            font-size: 1.22rem;
            i {
                color: $primary-color;
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .files {
            padding-bottom: 1rem;
            a {
                font-size: .7rem;
                i {
                    // background-color: $secondary-color;
                    // color: #fff;
                    padding: .2rem;
                }
            }
        }
    }
}