.emprendimientos,
.Inicio {
    .navButtonLeft {
        font-size: 1.5rem;
        color: #fff !important;
        background: url(../../svg/LeftArrow.svg) no-repeat 50%;
        width: 2.5rem;
        height: 2.5rem;
    }
    .navButtonRight {
        font-size: 1.5rem;
        color: #fff !important;
        background: url(../../svg/RightArrow.svg) no-repeat 50%;
        width: 2.5rem;
        height: 2.5rem;
    }
    .owl-carousel {
        padding-top: 5vh;
        min-height: calc(85vh - 6.5rem);
        .owl-prev,
        .owl-next {
            //background: transparent url(../../svg/RightArrow.svg) no-repeat 50%;
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block !important;
            background-color: black;
            text-align: center !important;
            line-height: 3.2rem !important;
            &:hover {
                background-color: black !important;
            }
        }
        .owl-prev {
            left: 0px;
        }
        .owl-next {
            right: 0px;
        }
        .owl-prev i,
        .owl-next i {
            transform: scale(2, 5);
            color: #ccc;
        }
        .owl-item {
            .content-item {
                position: relative;
                min-height: calc(85vh - 6.5rem);
                a {
                    .content-img {
                        transition: 0.3s ease-in all;
                        height: 100%;
                        position: relative;
                        .img {
                            height: 60vh;
                            object-fit: cover;
                            object-position: center;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            margin: auto;
                            filter: grayscale(100%);
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            height: 60vh;
                            opacity: 0;
                            background-color: #d17604 !important;
                            mix-blend-mode: screen !important;
                            z-index: 999;
                        }
                        .text {
                            position: absolute;
                            bottom: 5px;
                            right: 0;
                            opacity: 0;
                            transition: 0.2s ease all;
                            width: 110%;
                            h2 {
                                font-size: 2.5rem;
                                font-family: "Proto Grotesk";
                                line-height: 1;
                                margin-bottom: 0;
                                color: $secondary-color;
                                font-weight: 200 !important;
                                text-transform: uppercase;
                                width: max-content;
                                display: inline-table;
                                margin-top: auto;
                            }
                            h5 {
                                line-height: 1;
                                display: block;
                                color: $secondary-color;
                                margin-top: auto;
                                font-weight: 300;
                                width: max-content;
                                margin-bottom: 0;
                            }
                            // &:hover {
                            //     transform: scale(1.2);
                            // }
                        }
                        &:hover {
                            transform: scale(1.25);
                            .text {
                                opacity: 1;
                                z-index: 999;
                            }
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .dark {
        .owl-carousel {
            background-color: $secondary-color;
            .owl-item {
                .content-item {
                    .content-img {
                        // &::before {
                        //     background-color: #000;
                        //     mix-blend-mode: normal;
                        // }
                        &:hover {
                            &::before {
                                // opacity: 0.2;
                            }
                        }
                        .text {
                            h2,
                            h5 {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .emprendimientos,
    .Inicio {
        .owl-carousel {
            min-height: auto;
            padding-top: 10vh;
            .owl-prev,
            .owl-next {
                width: 1.5rem;
                height: 1.5rem;
            }
            .owl-next .navButtonRight,
            .owl-prev .navButtonLeft {
                width: 100%;
                height: 100%;
                background-size: 50%;
            }
            .owl-item {
                .content-item {
                    .content-img {
                        .img {
                            height: 95vw;
                            background-size: cover;
                        }
                        &::before {
                            opacity: 0 !important;
                        }
                        .text {
                            position: relative;
                            opacity: 1;
                            width: 100%;
                            color: $secondary-color;
                            h2 {
                                font-size: 4vw;
                                width: 100%;
                            }
                            h5 {
                                font-size: 4vw;
                                width: 100%;
                                font-weight: 200;
                                line-height: 1.2;
                                margin-bottom: 0.5rem;
                                display: inline-block;
                                span {
                                    font-size: 4vw;
                                }
                            }
                        }
                        &:hover {
                            transform: none;
                        }
                    }
                }
            }
        }
    }
}