    header {
        height: 6.5rem;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: #fff;
        transition: .3s ease all;
        border-bottom: 2px solid $secondary-color;
        nav {
            height: 100%;
            .container-fluid {
                height: 100%;
            }
            .border-side {
                height: 100%;
                &.right {
                    border-right: 2px solid $secondary-color;
                    padding-right: 3.4rem;
                }
                &.left {
                    border-left: 2px solid $secondary-color;
                    padding-left: 3.4rem;
                    .img {
                        width: 2.1rem;
                        cursor: pointer;
                        display: block;
                        transition: .4s ease-in all !important;
                        i {
                            display: block;
                            background-color: $secondary-color;
                            height: 2px;
                            margin-bottom: .5rem;
                            transition: .4s ease-in-out all !important;
                            &:nth-child(1) {
                                width: 2rem;
                            }
                            &:nth-child(2) {
                                width: 2rem;
                            }
                            &:nth-child(3) {
                                width: 2rem;
                            }
                        }
                        &.open {
                            i {
                                &:nth-child(1) {
                                    transform: rotate( -45deg) translate(-.37rem, 0.4rem);
                                    width: 2rem;
                                }
                                &:nth-child(2) {
                                    opacity: 0;
                                    display: none;
                                }
                                &:nth-child(3) {
                                    width: 2rem;
                                    transform: rotate(225deg);
                                }
                            }
                        }
                    }
                }
                .logo-header {
                    width: 5.4rem;
                }
            }
            .middle-half {
                .section-name,
                .theme-selector {
                    height: 50%;
                    transition: .3s ease all;
                    cursor: pointer;
                    .container-icon {
                        height: 100%;
                        transition: .3s ease all;
                        padding: 0 1.5rem;
                        border-left: 2px solid $secondary-color;
                        h5 {
                            padding-right: 1rem;
                            min-width: 6.5rem;
                            transition: .3s ease all;
                        }
                        i {
                            font-size: 1.22rem;
                            color: $secondary-color;
                            &.icon-night {
                                font-size: 1.3rem;
                            }
                        }
                        .icon-star2 {
                            color: transparent;
                            -webkit-text-stroke: 1px $secondary-color;
                            &.selected {
                                color: $primary-color;
                                -webkit-text-stroke: 1px $primary-color;
                            }
                        }
                    }
                }
                .section-name {
                    border-bottom: 2px solid $secondary-color;
                    padding-right: 1.5rem;
                    h5 {
                        font-weight: 400;
                        text-transform: capitalize;
                    }
                }
                h5 {
                    font-size: 0.77rem;
                    margin-bottom: 0;
                    font-weight: 300;
                }
            }
            .menu-links {
                position: fixed;
                display: flex;
                align-items: center;
                left: 200%;
                padding-left: 0;
                right: 0;
                top: 6.5rem;
                bottom: 0;
                background: #fff;
                margin-bottom: 0;
                z-index: 999;
                transition: .4s ease-in all;
                &.open {
                    left: 0;
                }
                .container-fluid {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;
                    li {
                        display: block;
                        line-height: initial;
                        a {
                            font-size: 5rem;
                            text-decoration: none;
                            color: $secondary-color;
                            font-weight: 200;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            transition: .3s ease all;
                            .hover {
                                display: block;
                                overflow: hidden;
                                i {
                                    font-size: 3.5rem;
                                    transition: .4s ease all;
                                    display: block;
                                    margin-top: 0;
                                    margin-left: -100px;
                                    transform: rotate(-90deg);
                                    color: #E68811;
                                }
                            }
                            &:hover {
                                color: $primary-color;
                                .hover {
                                    i {
                                        margin-left: 10px;
                                    }
                                }
                            }
                            &.active {
                                color: $primary-color;
                                .hover {
                                    i {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .social-media {
                        margin-top: 2rem;
                        margin-bottom: 1.5rem;
                        margin-right: calc(2.1rem + 3.4rem);
                        .single {
                            display: flex;
                            transition: .4s ease all;
                            align-items: center;
                            justify-content: center;
                            // background-color: $secondary-color;
                            cursor: pointer;
                            margin-left: 1rem;
                            text-decoration: none;
                            .small-single {
                                width: .9rem;
                            }
                            img {
                                width: 1.5rem;
                            }
                            i {
                                font-size: 1.4rem;
                                transition: .3s ease all;
                                color: $secondary-color;
                            }
                            &:hover {
                                i {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
            .back-button {
                color: $secondary-color;
                font-size: 1.2rem;
                font-weight: 200;
                text-transform: uppercase;
            }
            .section-header {}
        }
        &.dark {
            background-color: $secondary-color;
            border-color: #fff;
            nav {
                .border-side {
                    &.right {
                        border-right: 2px solid #fff;
                    }
                    &.left {
                        border-left: 2px solid #fff;
                        .img {
                            i {
                                background-color: #fff;
                            }
                        }
                    }
                }
                .middle-half {
                    .section-name,
                    .theme-selector {
                        height: 50%;
                        h5 {
                            color: #fff;
                        }
                        .container-icon {
                            border-left: 2px solid #fff;
                            h5 {
                                color: #fff;
                            }
                            i {
                                color: #fff;
                            }
                        }
                    }
                    .section-name {
                        border-bottom: 2px solid #fff;
                    }
                }
                .menu-links {
                    background: $secondary-color;
                    .container-fluid {
                        li {
                            a {
                                color: #fff;
                                &:hover {
                                    color: $primary-color;
                                }
                                &.active {
                                    color: $primary-color;
                                }
                            }
                        }
                        .social-media {
                            .single {
                                i {
                                    color: #fff;
                                    &:hover {
                                        color: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .section-header {
                    color: #fff;
                }
                .back-button {
                    color: #fff;
                }
            }
        }
    }
    
    @media (max-width:992px) {
        header {
            height: 3.1rem;
            max-width: 100vw;
            nav {
                .border-side {
                    &.right {
                        padding-right: 1rem;
                        img {
                            width: 2.5rem;
                        }
                    }
                    &.left {
                        padding-left: .7rem;
                        .img {
                            width: unset;
                            i {
                                margin-bottom: .25rem;
                                &:nth-child(1) {
                                    width: 1.1rem;
                                }
                                &:nth-child(2) {
                                    width: 1.1rem;
                                }
                                &:nth-child(3) {
                                    width: 1.1rem;
                                }
                            }
                            &.open {
                                i {
                                    &:nth-child(1) {
                                        transform: rotate( -45deg) translate(-5px, 5px);
                                        width: 1.1rem;
                                    }
                                    &:nth-child(2) {
                                        opacity: 0;
                                        display: none;
                                    }
                                    &:nth-child(3) {
                                        width: 1.1rem;
                                        transform: rotate(225deg);
                                    }
                                }
                            }
                        }
                    }
                    .logo-header {
                        width: 2.4rem;
                    }
                }
                .middle-half {
                    .section-name,
                    .theme-selector {
                        .container-icon {
                            padding: 0px .5rem;
                            i {
                                font-size: .72rem;
                                &.icon-night {
                                    font-size: .72rem;
                                }
                            }
                            h5 {
                                display: none;
                                min-width: 4rem;
                            }
                        }
                        h5 {
                            font-size: .45rem;
                        }
                    }
                }
                .menu-links {
                    top: 3.1rem;
                    align-items: flex-start;
                    .container-fluid {
                        justify-content: start;
                        margin-top: 2rem;
                        li {
                            margin-bottom: .8rem;
                            a {
                                font-size: 1.5rem;
                                &::after {
                                    width: .7rem;
                                    height: .7rem;
                                    display: none;
                                }
                                .hover {
                                    i {
                                        font-size: .7rem;
                                    }
                                }
                                &.active {
                                    .hover {
                                        i {
                                            margin-left: 3px;
                                        }
                                    }
                                }
                            }
                        }
                        .social-media {
                            width: 100%;
                            margin-right: 0;
                            position: absolute;
                            bottom: 1rem;
                            .single {
                                width: 2rem;
                                margin-left: 0;
                                margin-right: .5rem;
                                height: 2rem;
                                .small-single {
                                    width: .7rem;
                                }
                                i {
                                    font-size: .95rem;
                                }
                            }
                        }
                    }
                }
                .back-button {
                    font-size: .8rem;
                }
                .section-header {
                    font-size: .55rem;
                }
            }
        }
    }