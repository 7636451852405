.Emprendimiento {
    .contact {
        padding-bottom: 5rem;
        padding-top: 4rem;
        background-color: #CCCFCA;
        .obligatory {
            font-size: 1vw;
            font-weight: 300;
            text-transform: uppercase;
        }
        .section_indicator {
            border-bottom: 1px solid #000;
            span {
                line-height: 1;
                padding-top: 1rem;
                color: #000;
            }
        }
        h2 {
            // font-size: 3.25rem;
            // font-weight: 300;
            // color: #000;
        }
        .contact-items {
            width: auto;
            a,
            p {
                color: #000;
                font-weight: 200;
                font-size: 1.3rem;
                text-decoration: none;
                transition: 0.3s ease all;
            }
            a:hover {
                color: $primary-color;
            }
        }
        form {
            input,
            textarea {
                color: #000;
                position: relative;
                border-bottom: 1px solid #000;
                &::placeholder {
                    color: #000;
                }
            }
            button {
                color: #000;
                border-color: #000;
                &:hover {
                    color: $primary-color;
                    border-color: $primary-color;
                }
            }
        }
        .social-media {
            i {
                color: #000;
                &:hover {
                    color: $tertiary-color;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Emprendimiento {
        .contact {
            padding-top: 2rem;
            padding-bottom: 3rem;
            .obligatory {
                font-size: 3vw;
            }
            .section_indicator {
                margin-bottom: 1.5rem;
            }
            h2 {
                // font-size: 2.1rem;
                // margin-bottom: 1.5rem;
            }
            .contact-items {
                a,
                p {
                    text-decoration: none;
                    margin: 0;
                    display: block;
                    border-bottom: none;
                    font-size: 0.8rem;
                }
            }
        }
    }
}