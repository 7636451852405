.Emprendimiento {
    .blue_prints,
    .files {
        .content-img {
            // padding: .5rem;
            position: relative;
            display: block;
            // border: 1px solid #191919;
            .img-bluepints {
                width: 100%;
            }
            .expand {
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 99;
            }
        }
        h2 {
            // font-weight: 300;
            // font-size: 2.27rem;
        }
        h5 {
            font-size: 1.45rem;
            font-weight: 300;
        }
        .border-blueprints {
            border-bottom: 2px solid #191919;
            width: 100%;
            height: 1px;
            margin: 4rem 0;
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .blue_prints,
        .files {
            h2 {
                // font-size: 1rem;
                // margin-bottom: 2rem;
            }
            h5 {
                font-size: .8rem;
            }
            .content-img {
                margin-bottom: 1rem;
                img {}
            }
            .border-blueprints {
                margin: 2rem 0;
            }
        }
    }
}