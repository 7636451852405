.Inicio {
    .novelties {
        background-color: $secondary-color;
        padding-bottom: 2rem;
        .section_indicator {
            padding: 1rem 0;
            border-color: #fff;
            border-top: 1px solid rgba(255, 255, 255, 0.201) !important;
            span {
                color: #fff;
            }
        }
        h2 {
            font-size: 1.3rem;
            font-weight: 300;
            color: #fff;
        }
        a {
            span {
                line-height: 1;
                height: 2.3rem;
            }
        }
    }
}

.novelties-grid {
    .novelty-content-item {
        height: max-content;
    }
}

.home .novelty-content-item {
    height: 45vh;
}

.novelty-content-item {
    z-index: 9;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        background-color: #d17604;
        transition: 0.4s ease all;
        mix-blend-mode: screen;
        z-index: 1;
    }
    &.home {
        height: 45vh;
    }
    &.outstanding::before {
        background-color: gray;
    }
    a {
        color: #191919!important;
    }
    .hover {
        transition: 0.4s ease all;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;
        opacity: 0;
        top: 0;
        width: 100%;
        text-decoration: none;
        color: #191919;
        p {
            position: absolute;
            bottom: 0;
            left: 0.8rem;
            right: 6rem;
            line-height: 1.2;
            font-size: 1.2rem;
            transition: 0.4s ease all;
            color: #191919 !important;
        }
        a {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            background-color: #191919;
            height: 4rem;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 4rem;
            transition: 0.4s ease all;
            text-decoration: none;
            margin-bottom: 0;
            padding-bottom: 0.5rem;
            font-size: 2.5rem;
            font-weight: 200;
            transition: 0.4s ease all;
            color: #fff!important;
            transform: translateY(-600%);
            border-bottom: none;
        }
        h2 {
            font-size: 2.5rem;
            font-weight: 300;
            margin-left: 0.8rem;
            margin-top: 0.5rem;
            color: $secondary-color !important;
            text-transform: uppercase;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: grayscale(1);
    }
    &:hover {
        &::before {
            opacity: 1;
        }
        .hover {
            opacity: 1;
            a {
                transform: translateY(0);
            }
        }
    }
}

@media (max-width: 992px) {
    .Inicio {
        .novelties {
            .owl-carousel {
                margin-top: 0;
            }
            h2 {
                font-size: 0.7rem;
                font-weight: 300;
            }
            a {
                font-size: 1rem;
                span {
                    font-size: 1rem;
                    height: 1.4rem;
                    line-height: 1rem;
                }
            }
            .owl-stage {
                padding-left: 0 !important;
            }
        }
    }
    .novelty-content-item {
        &::before {
            opacity: 1 !important;
        }
        &.featured {
            height: 50vh;
        }
        .hover {
            opacity: 1;
            p {
                // font-size: 0.75rem;
                font-size: 50%;
                width: 70%;
                font-weight: 400;
            }
            a {
                font-size: 1.5rem;
                margin: auto;
                width: 2rem;
                height: 2rem;
                padding-top: 5px;
                transform: translate(calc(0% + .5rem), calc(0% + .5rem));
            }
            h2 {
                font-size: 1rem;
            }
        }
    }
    .novelty-content-items {
        border-bottom: 1px solid #fff;
        &::before {
            opacity: 1 !important;
        }
        .hover {
            position: relative !important;
            opacity: 1;
            padding: 0 10px;
            p,
            h2,
            a {
                position: relative;
                margin-left: 0;
                left: 0;
            }
            h2 {
                font-size: 1.1rem;
                color: #fff;
            }
            p {
                font-size: 0.6rem;
                color: #fff;
                font-weight: 300;
                display: block;
                width: 100%;
            }
            a {
                margin: auto;
                width: 2rem;
                height: 2rem;
                display: flex;
                padding-top: 5px;
                align-items: center;
                justify-content: center;
                transform: translateY(0);
            }
        }
    }
}

@media (min-width: 992px) {
    .Inicio {
        .novelties {
            .owl-carousel {
                display: none !important;
            }
        }
    }
}