.Emprendimiento {
  .details {
    p {
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1.86rem;
      button {
        border: none;
        background-color: transparent;
        font-size: 1.2rem;
        font-weight: 400;
        padding-bottom: 0px;
        border-bottom: 1px solid #000;
      }
    }
    .item {
      display: inline-block;
      font-size: 1.7rem;
      text-transform: uppercase;
      font-weight: 200;
      i {
        margin-right: 1rem;
        font-size: 1rem;
        transform: rotate(-90deg);
        color: $primary-color;
      }
    }
  }
}

@media (max-width: 992px) {
  .Emprendimiento {
    .details {
      p {
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1;
        button {
          font-size: 0.8rem;
        }
      }
      .item {
        font-size: 0.6rem;
        width: 47%;
        // white-space: nowrap;
        overflow: scroll;
        // text-overflow: clip;
        // overflow-wrap: break-word;
        i {
          font-size: 0.5rem;
        }
      }
    }
  }
}
