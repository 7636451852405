.Emprendimiento {
    .location {
        padding-top: 4rem;
        padding-top: 3rem;
        border-top: 2px solid #000;
        h3 {
            font-weight: 300;
            color: $primary-color;
            margin-bottom: 2rem;
            font-size: 2.27rem;
            display: inline-block;
        }
        h5 {
            font-weight: 200;
            font-size: 2.36rem;
            margin-bottom: 2rem;
            display: inline-block;
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .location {
            padding-top: 1rem;
            padding-top: 2rem;
            h3 {
                display: block;
                margin-bottom: 0;
                font-size: 1rem;
            }
            h5 {
                font-size: 1rem;
            }
        }
    }
}