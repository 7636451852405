.title-1 {
    font-size: 7vw;
    font-weight: 200;
    text-transform: uppercase;
}

.title-2 {
    font-size: 5vw;
    font-weight: 200;
    text-transform: uppercase;
    color: $secondary-color;
}

.title-3,
.title-3-white {
    font-size: 2.2rem;
    font-weight: 200;
    color: #fff;
    line-height: 1.2;
    //   text-transform: uppercase;
}

.title-3-white {
    color: #000;
}

.title-4 {
    font-size: 2.2rem;
    font-weight: 300;
}

.paragraph-1 {
    font-family: Proto Grotesk;
    font-size: 1.3rem;
    margin-top: 2rem;
    font-weight: 300;
    line-height: 1;
}

.link-1,
.link-1-dark,
.link-2-dark {
    font-size: 2.1rem;
    color: $secondary-color;
    border-bottom: 1px solid $secondary-color;
    text-decoration: none;
    font-weight: 200;
    &:hover {
        color: $primary-color;
        border-bottom-color: $primary-color;
        ;
    }
}

.link-1-dark,
.link-2-dark {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.link-2-dark {
    font-size: 1.25rem;
}

@media (max-width: 992px) {
    .title-1 {
        font-size: 6vw;
        line-height: 2rem;
    }
    .title-2 {
        font-size: 6vw;
        line-height: 2rem;
    }
    .title-3 {
        font-size: 0.85rem;
        // line-height: 2rem;
    }
    .title-3-white {
        font-size: 5vw;
        // line-height: 2rem;
    }
    .title-4 {
        font-size: 1.2rem;
        line-height: 1;
    }
    .paragraph-1 {
        font-size: 0.72rem;
        line-height: 1.5;
    }
    .link-1,
    .link-1-dark {
        // font-size: 1.2rem;
        font-size: 6vw;
    }
    .link-2-dark {
        font-size: 4.5vw;
    }
}