* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 22px;
}

body {
    font-family: "Proto Grotesk", "system-ui";
}

.caretUp {
    color: #fff;
    font-size: 1rem;
    font-weight: 200;
    &:hover {
        cursor: pointer;
        color: $primary-color !important;
        filter: invert(46%) sepia(87%) saturate(524%) hue-rotate(355deg) brightness(108%) contrast(92%);
    }
}

.dark-theme {
    background-color: #191919 !important;
    color: #fff;
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a,
    span,
    input,
    textarea,
    button {
        color: #fff !important;
        border-color: #fff !important;
        &::placeholder {
            color: #fff !important;
        }
    }
    .section_indicator,
    .section_indicator_end {
        border-color: #fff !important;
    }
    .social-media .single i {
        color: #fff !important;
    }
}

.dark {
    .owl-theme .owl-nav {
        margin-top: 0;
    }
}

#progressBarContainer {
    position: fixed;
    z-index: 1000;
    background: #666666;
    width: 6rem;
    top: 20rem;
    left: -1rem;
}

#progressBar {
    background: $primary-color;
    transform-origin: top left;
}

#progressBarContainer,
#progressBar {
    height: 2px;
    transform: rotate(90deg);
}

.section_indicator {
    border-bottom: 2px solid $secondary-color;
    padding-bottom: 0.9rem;
    margin-bottom: 4rem;
    span {
        width: 100%;
        font-size: 0.68rem;
        font-weight: 300;
    }
}

#text-form {
    color: white;
    font-weight: 300;
    background-color: $primary-color;
    padding: 0.4rem;
}

.social-media {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .single {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        transition: 0.4s ease all;
        align-items: center;
        justify-content: center;
        // background-color: $secondary-color;
        cursor: pointer;
        margin-right: 2rem;
        border-bottom: none !important;
        text-decoration: none;
        .small-single {
            width: 0.9rem;
        }
        img {
            width: 1.5rem;
        }
        i {
            font-size: 1.4rem;
            color: $primary-color;
        }
        &:hover {
            i {
                color: $primary-color;
            }
        }
    }
}

button:disabled {
    cursor: not-allowed !important;
}

.black-link,
.white-link {
    font-weight: 200;
    text-decoration: none;
    font-size: 1.75rem;
    text-transform: uppercase;
}

.black-link {
    color: #000;
    border-bottom: 1px solid #000;
    &:hover {
        color: #000;
    }
}

.white-link {
    color: #fff;
    border-bottom: 1px solid #fff;
    &:hover {
        color: #fff;
    }
}

.map {
    height: 60vh;
}

@media (max-width: 992px) {
    .section_indicator {
        border-bottom: 1px solid $secondary-color;
        padding-bottom: 5px;
        margin-bottom: 2rem;
        span {
            font-size: 0.4rem;
        }
    }
    .section_indicator_end {
        span {
            font-size: 0.4rem!important;
        }
    }
    .social-media {
        width: 100%;
        text-decoration: none;
        .single {
            width: 1.6rem;
            margin-right: 0.5rem;
            height: 1.6rem;
            .small-single {
                width: 0.6rem;
            }
            img {
                width: 0.8rem;
            }
        }
    }
    .map {
        height: 55vh;
        position: relative;
    }
    .caretUp {
        margin-top: 3vh;
    }
}

@media (min-width: 992px) {
    .container-fluid {
        max-width: 95%;
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    html {
        font-size: 13px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    html {
        font-size: 16px;
    }
}

@media (min-width: 1500px) and (max-width: 1920px) {
    html {
        font-size: 18px;
    }
}

@media (min-width: 1920px) {
    html {
        font-size: 20px;
    }
}

@media (max-width: 992px) {
    html,
    body {
        max-width: 100vw;
        overflow-x: hidden;
    }
    #progressBarContainer {
        left: -2.5rem;
    }
    .black-link,
    .white-link {
        font-size: 1rem;
    }
}