.Inicio {
  .hero {
    padding-bottom: 4.5rem;
    padding-top: calc(4.5rem + 6.5rem);
    text-transform: uppercase;
    h1 {
      
      a {
        font-size: 2.05rem;
        color: $secondary-color;
        font-weight: 200;
        text-decoration: none;
        vertical-align: text-top;
        line-height: 110px;
        padding-bottom: 0;
        border-bottom: 1px solid $secondary-color;
      }
    }
  }
}

@media (max-width: 992px) {
  .Inicio {
    .hero {
      padding-top: calc(3.1rem + 1rem);
      padding-bottom: .4rem;
      h1 {
        a {
          font-size: 1.3rem;
          vertical-align: auto;
          display: inline-block;
          line-height: unset;
        }
      }
    }
  }
}
