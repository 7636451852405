.Servicios {
    .main {
        padding-top: calc(6.5rem + 4rem);
        p {
            // font-size: 1.2rem;
            // font-weight: 300;
            // line-height: 1.5;
            text-transform: uppercase;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            animation: zoomin 35s infinite alternate;
        }
        .img-wrapper {
            overflow: hidden;
            height: 85vh;
        }
        @keyframes zoomin {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(1.5);
            }
        }
    }
}

@media (max-width: 992px) {
    .Servicios {
        .main {
            padding-top: calc(3.1rem + 1rem);
            .img-wrapper {
                overflow: hidden;
                height: 40vh;
            }
            img {
                height: 50vh;
            }
            p {
                // font-size: 0.9rem;
                // line-height: 0.95rem;
                margin-bottom: 0;
                padding-bottom: .5rem;
                width: 75%;
                // margin-top: 1rem;
            }
        }
    }
}